<template>
    <Guest>
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white uppercase" v-if="showSuccess">
                {{ t('email_verified') }}
            </h2>
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white uppercase" v-if="showError">
                {{ t('error') }}
            </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <section class="section section--top">
                    <div class="container container--custom">
                        <div class="row justify-content-center">
                            <div class="col-md-6">
                                <div class="block block--private">
                                    <div v-if="showSuccess">
                                        <h2 class="text-lg text-black font-bold relative">{{ t('succes') }}</h2>
                                        <p class="mt-4 leading-7 text-gray-800">{{ t('email_verified_redirect', {seconds: redirectDelay / 1000}) }}</p>
                                    </div>
                                    <div v-if="showError">
                                        <h2 class="text-lg text-black font-bold relative">{{ t('woops') }}</h2>
                                        <p class="mt-4 leading-7 text-gray-800">{{ t('something_wrong', {error: errorMessage}) }} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </Guest>
</template>
<script>
// credit Stefan Knegt: https://gitlab.com/lifebooq/lifebooq-web/-/blob/master/src/views/EmailVerified.vue
import axios from 'axios'
import Guest from "@/wrappers/Guest";
import store from '@/store';
import useI18n from "@/modules/globalI18n";

export default {
    name: "EmailVerification",
    components: {
        Guest,
    },
    setup() {
        return useI18n({
            viewPrefix: "EmailVerification"
        });
    },
    data () {
        return {
            showSuccess: false,
            showError: false,
            errorMessage: "",
            redirectDelay: 5000, // ms
        }
    },
    methods: {
        verify () {
            axios.get('v1/trainee/auth/email/verify/' + this.$route.params.id + '/' + this.$route.params.hash + '?expires=' + this.$route.query.expires + '&signature=' + this.$route.query.signature)
                .then(() => {
                    this.showSuccess = true;
                    
                    setTimeout(() => {
                        const user = store.getters['getUser'];
                        user.verified = 1;
                        store.commit('setUser', user);
                        this.$router.push( { name: 'dashboard' } )
                    }, this.redirectDelay);
                })
                .catch(error => {
                    this.errorMessage = error.response.data.message;
                    this.showError = true
                    
                    console.error(error)
                })
        }
    },
    created () {
        this.verify()
    }
}
</script>